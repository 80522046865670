import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import Slider from "react-slick";

import { ButtonBare } from "../../Button";
import CasesCarouselItem from "./CasesCarouselItem";

import BlackArrow from "../../../assets/images/svg/icons/arrows/arrow-b.svg";


const CasesCarousel = ({
  heading,
  buttonText,
  pageLink
}) => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          filter: {
            frontmatter: {
              template: {
                eq: "case"
              }
              settled: {
                eq: false
              }
              draft: {
                ne: true
              }
            }
          }
          sort: {
            fields: [frontmatter___date]
            order: DESC
          }
        ) {
          edges {
            node {
              frontmatter {
                title
                date(formatString: "MMMM DD, YYYY")
                location
                featuredImage {
                  childImageSharp {
                    fluid(maxWidth: 600, quality: 80) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                altText              
              }
              fields {
                slug
              }
            }
          }
        }
      }
    `
  );

  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1536,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          arrows: true,
          dots: true
        }
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          arrows: true
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: false
        }
      },
    ]
  }

  const casesCarouselItems = allMarkdownRemark.edges.map(node => {
    return (
      <CasesCarouselItem
        title={ node.node.frontmatter.title }
        date={ node.node.frontmatter.date }
        location={ node.node.frontmatter.location }
        featuredImage={ node.node.frontmatter.featuredImage.childImageSharp.fluid }
        altText={ node.node.frontmatter.altText }
        pageLink={ node.node.fields.slug }
      />
    );
  });

  return (
    <section className="container mx-auto my-16 px-6 lg:px-28">
      { heading &&
        <div className="flex flex-row justify-between items-center mb-8">
          <h2 className="text-3xl font-thin">{ heading }</h2>
          <Link
            to={ pageLink }
          >
            <BlackArrow className="w-6 lg:hidden" />
          </Link>
          <div className="hidden lg:block">
          <ButtonBare
            buttonText={ buttonText }
            buttonPosition="right"
            pageLink={ pageLink }
          />
          </div>
        </div>
      }
      <div style={ {marginLeft: -1 + 'rem', marginRight: -1 + 'rem'} }>
        <Slider
          { ...settings }
        >
          { casesCarouselItems }
        </Slider>
      </div>
    </section>
  )
}

export default CasesCarousel;