import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import CaseColumn from "./CaseColumn";


const CaseGrid = ({
  heading
}) => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          filter: {
            frontmatter: {
              template: {
                eq: "case"
              }
              settled: {
                eq: false
              }
              draft: {
                ne: true
              }
            }
          }
          sort: {
            fields: [frontmatter___date]
            order: DESC
          }
        ) {
          edges {
            node {
              frontmatter {
                title
                date(formatString: "MMMM DD, YYYY")
                location
                description
                featuredImage {
                  childImageSharp {
                    fluid(maxWidth: 1000, quality: 80) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                altText
              }
              fields {
                slug
              }
            }
          }
        }
      }
    `
  );

  const caseColumns = allMarkdownRemark.edges.map(node => {
    return (
      <CaseColumn 
        title={ node.node.frontmatter.title }
        date={ node.node.frontmatter.date }
        location={ node.node.frontmatter.location }
        featuredImage={ node.node.frontmatter.featuredImage.childImageSharp.fluid }
        altText={ node.node.frontmatter.altText }
        pageLink={ node.node.fields.slug }
      />
    )
  });
  
  return (
    <section className="container mx-auto my-16">
      { heading &&
        <h2 className="mb-8 px-6 lg:px-28 text-3xl font-thin">{ heading }</h2>
      }
      <div className="lg:grid lg:grid-cols-2 lg:gap-4 px-6 lg:px-28">
        { caseColumns }
      </div>
    </section>
  )
}

export default CaseGrid;